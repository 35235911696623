/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Demo} from "./";
import {MultiLangCode} from "./../../../src/components/MultiLangCode";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    code: "code",
    h3: "h3",
    pre: "pre",
    blockquote: "blockquote",
    strong: "strong",
    br: "br",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Aside, Tweet} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  if (!Tweet) _missingMdxReference("Tweet", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Here is where I would put an intro to an article about using files on the web, ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=omguEZ7jy5E"
  }, "IF I HAD ONE"), "."), "\n", React.createElement(_components.h2, {
    id: "getting-a-file"
  }, "Getting a ", React.createElement(_components.code, null, "File")), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/File"
  }, React.createElement(_components.code, null, "File"), " object"), " lets you read a bunch of information about a file, including the contents."), "\n", React.createElement(_components.h3, {
    id: "the-input-typefile-"
  }, "The ", React.createElement(_components.code, null, "<input type=\"file\" />")), "\n", React.createElement(_components.p, null, "Ol’ reliable, the ", React.createElement(_components.code, null, "<input>"), " element."), "\n", React.createElement(_components.p, null, "Give it a ", React.createElement(_components.code, null, "type"), " attribute of ", React.createElement(_components.code, null, "\"file\""), ", and a button appears that opens your OS’s file picker when clicked."), "\n", React.createElement(_components.p, null, "An ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file"
  }, React.createElement(_components.code, null, "<input type=\"file\" />")), " has many extra features.\nIt’s a ", React.createElement(_components.a, {
    href: "#drop-zone"
  }, "drop zone"), " too.\nDrop a file on it, and it will behave like you opened that file picker and chose a file. ", React.createElement(_components.a, {
    href: "https://www.youtube.com/watch?v=Hm3JodBR-vs"
  }, "Neat"), "!"), "\n", React.createElement(_components.p, null, "After the user selects a file (or multiple) from the file picker, a ", React.createElement(_components.code, null, "\"change\""), " event is fired.\nThe file(s) will be accessible on ", React.createElement(_components.code, null, "event.target.files"), "."), "\n", React.createElement(_components.p, null, "It’s a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileList"
  }, React.createElement(_components.code, null, "FileList")), ".\nEvery item inside that list is a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/File"
  }, React.createElement(_components.code, null, "File")), "."), "\n", React.createElement(_components.p, null, "If a single file was selected, it’ll be the 0th element in that list."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html",
    title: "index.html"
  }, "<input type=\"file\" />\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js"
  }, "const inputEl = document.querySelector(\"input\");\ninputEl.addEventListener(\"change\", (event) => {\n  const file = event.target.files[0];\n  console.log(\"A file was selected\");\n  console.log(\"Name:\", file.name);\n});\n")), "\n", React.createElement(_components.h3, {
    id: "drop-zone"
  }, "Drop zone"), "\n", React.createElement(_components.p, null, "Another method to get a ", React.createElement(_components.code, null, "File"), " is using a drop zone.\nDragging and dropping a file is a nice user experience."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "By default, the browser prevents anything from happening when dropping something onto most HTML elements."), React.createElement(_components.p, null, "To change that, the element must handle both the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Document/dragover_event"
  }, React.createElement(_components.code, null, "\"dragover\"")), " and ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Document/drop_event"
  }, React.createElement(_components.code, null, "\"drop\"")), " events.")), "\n", React.createElement(_components.p, null, "Each handler calls ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Event/preventDefault"
  }, React.createElement(_components.code, null, "event.preventDefault()")), " to prevent further processing of the event."), "\n", React.createElement(_components.p, null, "You also might want to call ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Event/stopPropagation"
  }, React.createElement(_components.code, null, "event.stopPropagation()")), " in each handler.\nIt’s not required, but allowing the events to continue propagating can lead to unwanted behaviour."), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "I think it’s a bit weird too, but it’s necessary."), "\n"), "\n", React.createElement(_components.p, null, "The file(s) are accessible under ", React.createElement(_components.code, null, "event.dataTransfer.files"), " in the ", React.createElement(_components.code, null, "\"drop\""), " event handler."), "\n", React.createElement(_components.p, null, "Alternatively, you can use the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItemList"
  }, React.createElement(_components.code, null, "DataTransferItemList"), " API"), " to access the file(s).\nThis API is handy if you also want to support the dropping of things that are ", React.createElement(_components.strong, null, "not"), " ", React.createElement(_components.code, null, "File"), " objects."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html",
    title: "index.html"
  }, "<div>Welcome to THE DROP ZONE</div>\n")), "\n", React.createElement(MultiLangCode, {
    values: [{
      label: "dataTransfer.files"
    }, {
      label: "dataTransfer.items"
    }]
  }, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js"
  }, "const dropZoneEl = document.querySelector(\"div\");\ndropZoneEl.addEventListener(\"dragover\", (event) => {\n  event.preventDefault();\n});\ndropZoneEl.addEventListener(\"drop\", (event) => {\n  event.preventDefault();\n  console.log(\"Something was dropped\");\n\n  const file = event.dataTransfer.files[0];\n  console.log(\"Name:\", file.name);\n});\n")), React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js",
    hl: "9-12"
  }, "const dropZoneEl = document.querySelector(\"div\");\ndropZoneEl.addEventListener(\"dragover\", (event) => {\n  event.preventDefault();\n});\ndropZoneEl.addEventListener(\"drop\", (event) => {\n  event.preventDefault();\n  console.log(\"Something was dropped\");\n\n  if (event.dataTransfer.items[0].kind === \"file\") {\n    const file = event.dataTransfer.items[0].getAsFile();\n    console.log(\"Name:\", file.name);\n  }\n});\n"))), "\n", React.createElement(_components.h3, {
    id: "the-the-file-system-access-api"
  }, "The The File System Access API"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/File_System_Access_API"
  }, "File System Access API"), " is fairly new at the time of writing and available in Chromium browsers (Chrome and Edge)."), "\n", React.createElement(_components.p, null, "It differs from the previous methods in that you first get a file ", React.createElement(_components.strong, null, "handle"), ", which in turn lets you get the underlying file."), "\n", React.createElement(_components.p, null, "A benefit of this is that you can use that handle to also ", React.createElement(_components.strong, null, "write"), " to the file."), "\n", React.createElement(_components.p, null, "You get one (or multiple) ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle"
  }, React.createElement(_components.code, null, "FileSystemFileHandle"), "(s)"), " by calling a method on the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Window"
  }, React.createElement(_components.code, null, "window")), "."), "\n", React.createElement(_components.p, null, "This opens the OS’s file picker and returns a ", React.createElement(_components.code, null, "Promise"), " that resolves to an array of handles to the file(s) you just picked.", React.createElement(_components.br), "\n", "The file itself can then be accessed by calling the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle/getFile"
  }, React.createElement(_components.code, null, "getFile")), " method on a handle.", React.createElement(_components.br), "\n", "That method returns a ", React.createElement(_components.code, null, "Promise"), " that resolves to a ", React.createElement(_components.code, null, "File"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js"
  }, "const fileHandles = await window.showOpenFilePicker();\nconst file = await fileHandles[0].getFile();\nconsole.log(\"A file was selected\");\nconsole.log(\"Name:\", file.name);\n")), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "You can also access the handle to a directory by calling ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Window/showDirectoryPicker"
  }, React.createElement(_components.code, null, "window.showDirectoryPicker")), "."), React.createElement(_components.p, null, "Another neat way to get a filehandle is calling the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/getAsFileSystemHandle"
  }, React.createElement(_components.code, null, "getAsFileSystemHandle")), " method\non a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem"
  }, React.createElement(_components.code, null, "DataTransferItem")), " during the ", React.createElement(_components.a, {
    href: "#drop-zone"
  }, "drag and drop method"), ".")), "\n", React.createElement(_components.h2, {
    id: "using-a-file"
  }, "Using a ", React.createElement(_components.code, null, "File")), "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.code, null, "File"), " is a special kind of ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Blob"
  }, React.createElement(_components.code, null, "Blob")), " and can be used everywhere a ", React.createElement(_components.code, null, "Blob"), " can."), "\n", React.createElement(_components.p, null, "The metadata directly accessible on a ", React.createElement(_components.code, null, "File"), " object is browser-specific, what might be there in one browser, may not exist in another."), "\n", React.createElement(_components.p, null, "By metadata I mean properties like ", React.createElement(_components.code, null, "file.name"), ", ", React.createElement(_components.code, null, "file.type"), ", ", React.createElement(_components.code, null, "file.size"), ", …"), "\n", React.createElement(_components.p, null, "The most interesting thing is the contents of that file.\nThis being the web, there are multiple ways to read that data."), "\n", React.createElement(_components.h3, {
    id: "the-file-api"
  }, "The ", React.createElement(_components.code, null, "File"), " API"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/File"
  }, React.createElement(_components.code, null, "File"), " object"), " inherits a bunch of methods from ", React.createElement(_components.code, null, "Blob"), " that can retrieve all the data-y goodness your file holds."), "\n", React.createElement(_components.p, null, "For example ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Blob/text"
  }, React.createElement(_components.code, null, ".text()")), " and ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer"
  }, React.createElement(_components.code, null, ".arrayBuffer()")), " return a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
  }, React.createElement(_components.code, null, "Promise")), " that resolves to a string or an arraybuffer respectively.\nWhile ", React.createElement(_components.a, {
    href: ""
  }, React.createElement(_components.code, null, ".stream()")), " returns a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream"
  }, React.createElement(_components.code, null, "ReadableStream")), "."), "\n", React.createElement(_components.p, null, "Lots of options!"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Is the file an image and do you want to draw it in a ", React.createElement(_components.code, null, "<canvas>"), "?\nNo problem!\nCall ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/createImageBitmap"
  }, React.createElement(_components.code, null, "createImageBitmap(file)")), " and feed it to the canvas’ ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage"
  }, React.createElement(_components.code, null, "drawImage")), " method."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Do you want to represent the data as a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/Data_URLs"
  }, "Data URL"), "?\nSome example usages of those is populating the ", React.createElement(_components.code, null, "src"), " attribute of a ", React.createElement(_components.code, null, "<video>"), " or ", React.createElement(_components.code, null, "<img>"), " tag."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, "URL"), " API has a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL"
  }, React.createElement(_components.code, null, "createObjectURL")), " method that accepts a ", React.createElement(_components.code, null, "Blob"), "!"), "\n"), "\n"), "\n", React.createElement(_components.h3, {
    id: "the-filereader-api"
  }, "The ", React.createElement(_components.code, null, "FileReader"), " API"), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader"
  }, React.createElement(_components.code, null, "FileReader"), " API"), " is an event based API.\nA reader fires events you can listen to by defining an ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener"
  }, React.createElement(_components.code, null, "addEventListener")), "."), "\n", React.createElement(_components.p, null, "You can kick off an action for the reader to take by calling one of its methods: ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsText"
  }, React.createElement(_components.code, null, "readAsText")), ",\n", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsArrayBuffer"
  }, React.createElement(_components.code, null, "readAsArrayBuffer")), ",\n", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL"
  }, React.createElement(_components.code, null, "readAsDataURL")), ",\n", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsText"
  }, React.createElement(_components.code, null, "readAsText")), ",\nor ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsBinaryString"
  }, React.createElement(_components.code, null, "readAsBinaryString")), "."), "\n", React.createElement(_components.p, null, "Once that action completes, the reader will fire the ", React.createElement(_components.code, null, "\"load\""), " event, and the result will be available on ", React.createElement(_components.code, null, "reader.result"), "."), "\n", React.createElement(Aside, {
    variant: "danger"
  }, React.createElement(_components.p, null, "The usage of ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsBinaryString"
  }, React.createElement(_components.code, null, "readAsBinaryString")), " is discouraged.\nPrefer ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsArrayBuffer"
  }, React.createElement(_components.code, null, "readAsArrayBuffer")), ".")), "\n", React.createElement(_components.p, null, "This event-based API allows a bit more flexibility than using the ", React.createElement(_components.code, null, "Promises"), " of the ", React.createElement(_components.code, null, "File"), " API,\nlike showing a progress bar by listening to the ", React.createElement(_components.code, null, "\"progress\""), " event the reader will periodically fire while reading a file."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "You may want to manually send an ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Event"
  }, React.createElement(_components.code, null, "Event")), " to the reader."), React.createElement(_components.p, null, "An example is making a reader fire the ", React.createElement(_components.code, null, "\"error\""), " event while testing:\n", React.createElement(_components.code, null, "reader.dispatchEvent(new Event(\"error\"));"))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js"
  }, "const file = /* a File object */;\nconst reader = new FileReader();\n\nreader.addEventListener(\"load\", (event) => {\n  console.log(\"Successfully read:\", file.name);\n  console.log(\"Text contents:\", reader.result);\n})\n\nreader.readAsText(file);\n")), "\n", React.createElement(_components.h2, {
    id: "writing-to-files"
  }, "Writing to files"), "\n", React.createElement(_components.p, null, "Once you have a handle to a file, you can call the ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle/createWritable"
  }, React.createElement(_components.code, null, "createWritable"), " method"), "\nto get a ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/FileSystemWritableFileStream"
  }, React.createElement(_components.code, null, "FileSystemWritableFileStream")), ", which can be used to write to that file."), "\n", React.createElement(_components.p, null, "The snippet below writes “BOOP” to a file. Glorious."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html",
    title: "index.html"
  }, "<button>choose a file to write to</button>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js",
    title: "index.js"
  }, "const button = document.querySelector(\"button\");\n\nbutton.addEventListener(\"click\", async () => {\n  const handle = await window.showSaveFilePicker();\n  const writable = await handle.createWritable();\n  await writable.write(\"BOOP\");\n  await writable.close();\n});\n")), "\n", React.createElement(_components.p, null, "In the tweet below, I choose a file and then append either “boop” or “potato” to the file, depending on which button is clicked:"), "\n", React.createElement(Tweet, {
    tweetLink: "NMeuleman/status/1533177195283243008",
    theme: "dark"
  }), "\n", React.createElement(_components.h2, {
    id: "demo--code"
  }, "Demo & code"), "\n", React.createElement(_components.p, null, "Try selecting a video, an image, or a text file with any of the three input methods in the demo underneath."), "\n", React.createElement(_components.p, null, "By looking at the ", React.createElement(_components.code, null, "file.type"), ", the demo determines how it should read the file.\nIf it’s a video or an image, it reads the contents as a data URL and sets the ", React.createElement(_components.code, null, "src"), " for a ", React.createElement(_components.code, null, "<video>"), " or ", React.createElement(_components.code, null, "<img>"), " tag."), "\n", React.createElement(_components.p, null, "Anything else, and it reads and displays the plain text that’s inside that file."), "\n", React.createElement(_components.p, null, "You can view ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/nicky-blog/blob/master/data/garden/files-on-the-web/index.js"
  }, "the code for this demo")), "\n", React.createElement(Demo));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
